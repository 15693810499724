import { corpDomains, fileType } from './domainsAndTypes.js'

export function notCorpEmail(value) {
    const lowerValue = value.toLowerCase()
    const domain = lowerValue.split('@')

    return domain[1] ? corpDomains.indexOf(domain[1]) === -1 : false
}

// eslint-disable-next-line no-unused-vars
export function usrFileType(value, input) {
    const inp = document.getElementById('usr_file')
    if (inp.files.length === 0) {
        return false
    }

    const fileName = inp.files[0].name
    const lowerValue = fileName.toLowerCase()
    const type = lowerValue.split('.')

    return type[1] ? fileType.indexOf(type[1]) !== -1 : false
}

export function maxFileSize(value, input, size) {
    const inp = document.getElementById('usr_file')
    if (inp.files.length === 0) {
        return false
    }

    const fileSize = inp.files[0].size

    return fileSize <= size
}

export function minDigitsLength(value, input, count) {
    const digitCount = value.replace(/\D+/g, '').length

    return digitCount >= count
}

export function maxDigitsLength(value, input, count) {
    const digitCount = value.replace(/\D+/g, '').length

    return digitCount <= count
}

export function isEmail(value) {
    // eslint-disable-next-line max-len, no-useless-escape
    const regex =
        // eslint-disable-next-line max-len, no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(value)
}

// check only numbers
export function isNumber(value) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\d\-\+\(\)\s]+$/

    return regex.test(value)
}

export function isTextFieldNotEmpty(value) {
    return value.trim().length > 0
}

export function isSumm(value, num1, num2) {
    return value == num1 + num2
}

export function isFieldEmpty(input) {
    if (!input || !(input instanceof HTMLInputElement)) {
        return false
    }

    if (input.type === 'text' || input.type === 'email' || input.type === 'password') {
        return input.value.trim() === ''
    }

    if (input.type === 'checkbox') {
        return !input.checked
    }

    return false
}
